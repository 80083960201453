// import "@/www/assets/css/global/common.scss";
// import { Drawer } from "./common/Drawer";
import "../css/style/module/common.scss";
import SmoothScroll from 'smooth-scroll';
(function (window, document, $) {
    'use strict';
    //header開閉
    $(".o-menuToggle").on('click touchend', function (event) {
        event.preventDefault();
        $("#is-body").toggleClass("is-active");
    });

    //SmoothScroll
    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,//スクロールする速さ
        // header: '#header'//固定ヘッダーがある場合
    });

    var removeBodyClass = function (event) {
        if ($("#is-body").hasClass("is-active")) {
            $("#is-body").removeClass("is-active");
        }
    };
    // Listen for scroll events
    document.addEventListener('scrollStart', removeBodyClass, false);


})(window, document, $);
